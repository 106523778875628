































import {
  defineComponent,
  PropType,
  computed
} from '@nuxtjs/composition-api'
import { useImage } from '@wemade-vsf/composables';
import type { Product } from '@wemade-vsf/catalog/lib/runtime/product/types';
import { useProductLabels } from '@wemade-vsf/catalog/lib/runtime/product/composables/useProductLabels';
import type { LabelDataInterface } from '@wemade-vsf/magento-api';

interface LabelWithAttributes extends LabelDataInterface {
  labelClasses?: string;
  hasImage?: boolean;
  dynamicComponent: string;
  hasTooltip: boolean;
  tooltipStyle: string;
  tooltipClass: string;
  componentData: {
    href?: string,
    target?: string
  }
}

export  default defineComponent({
  name: 'ProductLabels',
  props: {
    product: {
      type: Object as PropType<Product>,
      default: () => []
    },
    area: {
      type: String,
      default: 'list'
    }
  },
  setup (props) {
    const { getLabels } = useProductLabels()
    const { getMagentoImage } = useImage();

    const labels = computed<LabelWithAttributes[]>(() => {
      let currentLabels = getLabels(props.product);

      return currentLabels.map(label => {
        let txt = label.txt;
        let style = label.style?.endsWith(';') ? label.style : label.style + ';';

        const hasImage = label.image ? true : false;
        let labelClasses = [
          ...hasImage ? ['has-image'] : [],
          ...label.position ? [`pos-${label.position}`] : []
        ];

        if (hasImage) {
          const size = label.size ? label.size : "30";

          style = `${style} width: ${size}%;`;
        }

        const dynamicComponent = label.redirect_url
          ? 'a'
          : 'div';

        const componentData = {
          ...dynamicComponent === 'a' ? { href: label.redirect_url, target: '_blank' } : {},
        }

        const tooltipStatus = label.tooltip_settings?.status ?? 1;
        const hasTooltip = tooltipStatus > 1;
        const tooltipClass = hasTooltip
          ? (tooltipStatus === 3 ? 'desktop-only' : '')
          : '';
        const tooltipStyle = hasTooltip
          ? `background-color: ${label.tooltip_settings.color}; color: ${label.tooltip_settings.text_color}`
          : '';

        return {
          ...label,
          txt,
          style,
          hasImage,
          hasTooltip,
          tooltipClass,
          tooltipStyle,
          dynamicComponent,
          componentData,
          labelClasses: labelClasses.join(' ')
        };
      })
    });

    
    return {
      labels,
      getMagentoImage
    }
  }
})
